import * as React from 'react';
import { t } from 'i18next';
import { TableWrapperProps } from './types';
import { NoSSRTable, TableHeader } from '../';
import { EmptyTableView } from '../Table/Table.styles';

export const TableWrapper: React.FC<TableWrapperProps> = ({
  baseTableHeaders,
  loading,
  fetching,
  rowsData,
  filteredRows,
  dataTotal,
  hiddenColumns,
  setHiddenColumns,
  setSelectedRowsIds,
  setSelectedRows,
  loadMoreData,
  tableSort,
  setTableSort,
  onAllRowsCheckboxToggle,
  isAllRowsCheckboxSelected,
  setFlatRowsIds,
  dataName,
  tableType,
}: TableWrapperProps) => {
  const [selectedRowsTableIds, setSelectedRowsTableIds] = React.useState<
    Array<string>
  >([]);

  return (
    <div
      data-onboarding="dashboard-respondents-table"
      data-testid="Table-Wrapper"
    >
      <TableHeader
        isTableLoading={loading}
        dataTotal={dataTotal}
        currentlyFilteredRows={filteredRows}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
        dataName={dataName}
      />
      {rowsData.length > 0 ? (
        <NoSSRTable
          baseTableHeaders={baseTableHeaders}
          rowsData={rowsData}
          loading={loading}
          fetching={fetching}
          loadMoreData={loadMoreData}
          tableSort={tableSort}
          setTableSort={setTableSort}
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          setSelectedRowsIds={setSelectedRowsIds}
          setSelectedRows={setSelectedRows}
          setSelectedRowsTableIds={
            !loading ? setSelectedRowsTableIds : () => ''
          }
          selectedRowsTableIds={selectedRowsTableIds}
          setFlatRowsIds={setFlatRowsIds}
          onAllRowsCheckboxToggle={onAllRowsCheckboxToggle}
          isAllRowsCheckboxSelected={isAllRowsCheckboxSelected}
          tableType={tableType}
        />
      ) : (
        <EmptyTableView>
          {t(
            'You currently have no active proposals. Change the filters to show more.'
          )}
        </EmptyTableView>
      )}
    </div>
  );
};
